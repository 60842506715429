<template data-app>
  <div class="dashboard-content">
    <!-- Content -->
    <div class="card">
      <div class="filters my-2" style="padding: 10px 10px 0px">
        <h3 class="text-center">
          Glosario<v-btn
            color="#003a56"
            dark
            class="mb-2"
            style="float: right"
            @click.prevent="handleCreate()"
            >Crear
          </v-btn>
        </h3>
        <label>Filtrar por:</label>

        <v-form ref="formGlosary" v-model="valid" style="width: 100%" class="my-2"
          ><v-row class="mx-0" style="max-width: calc(100% - 90px)">
            <v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px">
              <v-text-field
                dense
                solo
                hide-details="auto"
                label="Búsqueda"
                v-model="filters.search"
              ></v-text-field>
            </v-col>

            <v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px">
              <multiselect
                track-by="id"
                label="name"
                deselect-label=""
                select-label=""
                placeholder="Tipo"
                v-model="filters.type"
                :options="typeOptions"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="false"
              ></multiselect>
            </v-col>
            <v-col cols="12" lg="1" md="2" sm="6" style="padding: 1px 2px">
              <multiselect
                style="min-width: 90px"
                track-by="id"
                label="name"
                deselect-label=""
                select-label=""
                placeholder="Activa"
                v-model="filters.active"
                :options="activeOptions"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="false"
              ></multiselect>
            </v-col>
            <v-btn
              color="#003a56"
              style="min-width: auto; position: absolute; right: 50px; height: 25px"
              dark
              class="px-1 py-1"
              v-if="filterActivated"
              @click.prevent="
                filterActivated = false
                cleanFilters()
                getWords()
              "
            >
              <v-icon dark> mdi-delete </v-icon>
            </v-btn>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="#003a56"
                  style="min-width: auto; position: absolute; right: 10px; height: 25px"
                  dark
                  class="px-1 py-1"
                  @click.prevent="
                    filterActivated = true
                    getWords()
                  "
                  ><v-icon dark> mdi-filter </v-icon>
                </v-btn>
              </template>
              <span>Filtrar</span>
            </v-tooltip>
          </v-row>
        </v-form>
      </div>

      <simple-list
        :title="''"
        :dense="true"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="loadingText"
        :hideFooter="true"
        :multiSort="false"
        :loading="loading"
        :class="'mb-4'"
        :sortable="true"
        :page="page"
        :pages="pages"
        :extraButtoms="false"
        :showCreate="true"
        :showDelete="true"
        :buttonLink="''"
        :orderOptions="orderOptions"
        @create:item="handleCreate()"
        @edit:item="handleEdit($event)"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
      />
    </div>
    <modal name="crudModal" :height="'75%'" :width="'80%'" :adaptive="true">
      <h3 class="text-center" v-if="flow == 'create'">Crear Término</h3>
      <h3 class="text-center" v-if="flow == 'edit'">Editar Término</h3>
      <div class="card" style="padding: 2rem; margin: 20px">
        <h5 style="margin-bottom: 0px">Datos generales</h5>
        <v-form ref="formGlosary" v-model="valid" style="width: 100%">
          <v-row class="m-0" style="align-items: flex-end">
            <v-col cols="12" md="6">
              <v-text-field
                :rules="[rules.required, rules.maxTitle]"
                label="Tilulo"
                v-model="title"
                counter
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-textarea
                counter
                :rules="[rules.required, rules.max]"
                label="Descripción corta"
                v-model="shortdesc"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row class="m-0">
            <v-col cols="12 mt-2">
              <vue-editor
                id="editor"
                class="text-box"
                v-model="description"
                placeholder="Descripción"
              />
            </v-col>
            <v-col cols="12" lg="6" md="6" sm="12">
              <v-row>
                <a
                  style="width: 40%"
                  :href="item.image.route"
                  v-if="flow == 'edit' && item.image && item.image.route"
                  target="_blank"
                  rel="noopener"
                  ><v-img :src="item.image.route"></v-img
                ></a>

                <v-file-input
                  style="width: 60%"
                  v-model="image"
                  chips
                  clearable
                  clear-icon="mdi-close-circle"
                  ref="file"
                  show-size
                  accept="image/png, image/jpeg, image/jpg, image/gif"
                  :label="
                    flow == 'create' ? 'Imagen de portada' : 'Click para cambiar imagen'
                  "
                ></v-file-input
              ></v-row>
            </v-col>
            <v-col cols="12" md="6" style="align-self: center">
              <multiselect
                track-by="id"
                label="name"
                placeholder="Seleccionar categorías"
                v-model="selectedCategories"
                :options="categories"
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="false"
              ></multiselect>
            </v-col>
            <v-col cols="12" lg="4" md="6">
              <v-checkbox
                :label="active ? ' Activa' : ' Inactiva'"
                v-model="active"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </div>

      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn
            style="margin: auto; margin-top: 10px; color: #fff"
            v-if="flow == 'create'"
            :disabled="!valid"
            color="#003a56"
            @click="createItem()"
          >
            Crear
          </v-btn>
          <v-btn
            style="margin: auto; margin-top: 10px; color: #fff"
            v-if="flow == 'edit'"
            :disabled="!valid"
            color="#003a56"
            @click="saveItem()"
          >
            Guardar
          </v-btn>

          <v-btn
            style="margin: auto; margin-top: 10px; color: #fff"
            color="#003a56"
            @click="reset"
          >
            Limpiar
          </v-btn>
          <v-btn
            style="margin: auto; margin-top: 10px; color: #fff"
            color="#003a56"
            @click="$modal.hide('crudModal')"
          >
            Cancelar
          </v-btn>
        </div>
      </v-col>
    </modal>
  </div>
</template>

<script>
  import SimpleList from 'Components/Listing/SimpleList.vue'
  import Multiselect from 'vue-multiselect'
  import { VueEditor } from 'vue2-editor'

  export default {
    name: 'Glosary',
    components: {
      SimpleList,
      Multiselect,
      VueEditor,
    },
    data() {
      return {
        config: {
          headers: {},
        },
        loading: false,
        headers: [
          // { text: 'Tipo', value: 'typeText' },
          { text: 'Nombre', value: 'title' },
          { text: 'Categorias', value: 'categoryText' },
          { text: 'Descripción', value: 'desc' },

          { text: 'Activo', value: 'activeText' },

          { text: 'Acciones', value: 'actions', align: 'center', width: '60px' },
        ],
        // types: [
        //   { code: '0', name: 'Empresas' },
        //   { code: '1', name: 'Blog' },
        //   { code: '2', name: 'Eventos' },
        //   { code: '3', name: 'Noticias' },
        // ],
        items: [],
        item: {},
        title: '',
        shortdesc: [],
        description: '',
        categories: [],
        selectedCategories: [],
        categoriesList: '',
        active: false,
        // selectedWords: [],
        page: 1,
        pages: 1,
        filterActivated: false,
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
          { id: 5, name: 'Fecha de publicación', order: 'date', by: 'desc' },
        ],
        filters: {
          search: '',
          type: null,
          active: null,
        },
        typeOptions: [{ id: null, name: 'Todos' }],
        activeOptions: [
          { id: null, name: 'Todas' },
          { id: 1, name: 'Activas' },
          { id: 0, name: 'Inactivas' },
        ],
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        name: '',
        type: '',
        image: null,
        loadingText: 'Cargando Términos',
        valid: true,

        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => v.length >= 8 || '8 caracteres como mínimo',
          max: (v) => v.length <= 400 || 'Debe poser menos de 400 caracteres',
          max: (v) => v.length <= 80 || 'Debe poser menos de 140 caracteres',
          maxNumber: (v) => v.length <= 18 || 'Debe poser menos de 18 caracteres',
          url: (v) => this.isURL(v) || 'La URL es inválida',
        },
      }
    },
    created() {
      this.getWords()
      this.getCategories()
    },
    computed: {},
    methods: {
      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getWords()
      },
      // fullName({ name, lastname, email }) {
      //   if (name && name != 'Todos') {
      //     if (lastname && email) {
      //       return `${name} ${lastname} | ${email}`
      //     } else {
      //       return name
      //     }
      //   } else {
      //     return name
      //   }
      // },
      cleanFilters() {
        this.filters = {
          search: '',
          type: null,
          active: null,
        }
      },

      async getWords() {
        this.loading = true
        let queryParams = ''
        let orderString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.filterActivated) {
          let searchString = ''
          let typeString = ''
          let activeString = ''

          if (this.filters.search != '') {
            searchString = '&search=' + this.filters.search
          }
          if (this.filters.type && this.filters.type.name) {
            typeString = '&type=' + this.filters.type.id
          }
          if (this.filters.active && this.filters.active.id !== null) {
            activeString = '&active=' + this.filters.active.id
          }

          queryParams = searchString + typeString + activeString
        }
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'words?page=' +
              this.page +
              orderString +
              queryParams
          )
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.from
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.items.map((x) => {
              switch (x.active) {
                case 0:
                  x.activeText = 'No'
                  break
                case 1:
                  x.activeText = 'Si'
                  break
              }

              x.shortdesc.length <= 50
                ? (x.desc = x.shortdesc)
                : (x.desc = x.shortdesc.slice(0, 50) + '...')

              let categoryText = ''
              x.categories.slice(0, 2).forEach((category) => {
                categoryText = categoryText + category.name + ', '
              })
              x.categoryText = categoryText.slice(0, categoryText.length - 2)

              x.image = x.uploads.length > 0 ? x.uploads[0] : {}
            })
            this.loading = false
          })
      },

      async getCategories() {
        axios
          .get(process.env.VUE_APP_API_DIRECTORY + 'categories?limit=100&type=4')
          .then((response) => {
            this.categories = response.data
          })
      },

      async handleCreate() {
        this.flow = 'create'
        this.title = ''
        this.shortdesc = ''
        this.description = ''
        this.image = null
        this.selectedCategories = ''
        this.active = false
        this.$modal.show('crudModal')
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.title = this.item.title
        this.shortdesc = this.item.shortdesc
        this.description = this.item.description
        this.image = null
        this.selectedCategories = this.item.categories
        this.active = this.item.active == 1 ? true : false
        this.$modal.show('crudModal')
      },

      validate() {
        this.$refs.form.validate()
      },

      reset() {
        this.$refs.form.reset()
      },

      async createItem() {
        let formData = new FormData()
        this.loading = true
        this.active = this.active ? 1 : 0

        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: this.$session.get('tokenSession')
            ? 'Bearer ' + this.$session.get('tokenSession')
            : 'Bearer ',
        }
        if (this.image) {
          formData.append('file', this.image)
        }
        this.selectedCategories.forEach((category) => {
          formData.append('categories[]', category.id)
        })

        formData.append('fileType', 'image')
        formData.append('title', this.title)

        formData.append('shortdesc', this.shortdesc)
        formData.append('description', this.description)
        formData.append('active', this.active)

        axios
          .post(process.env.VUE_APP_API_DIRECTORY + 'words', formData, this.config)
          .then((response) => {
            this.page = 1
            this.getWords()
            this.$modal.hide('crudModal')
            this.loading = false
          })
      },

      async saveItem() {
        let formData = new FormData()
        this.active = this.active ? 1 : 0

        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: this.$session.get('tokenSession')
            ? 'Bearer ' + this.$session.get('tokenSession')
            : 'Bearer ',
        }
        if (this.image) {
          formData.append('file', this.image)
          if (this.item.image) {
            formData.append('fileId', this.item.image.id)
          }
        }
        formData.append('fileType', 'image')
        formData.append('_method', 'patch')
        formData.append('title', this.title)
        formData.append('shortdesc', this.shortdesc)
        formData.append('description', this.description)
        this.selectedCategories.forEach((category) => {
          formData.append('categories[]', category.id)
        })
        formData.append('active', this.active)

        axios
          .post(
            process.env.VUE_APP_API_DIRECTORY + 'words/' + this.item.id,
            formData,
            this.config
          )
          .then((response) => {
            this.page = 1
            this.getWords()
            this.$modal.hide('crudModal')
            this.loading = false
          })
      },

      async deleteItem(item) {
        this.loading = true
        axios
          .delete(process.env.VUE_APP_API_DIRECTORY + 'words/' + item.id)
          .then((response) => {
            this.page = 1
            this.items = []
            this.getWords()
          })
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getWords()
      },
    },
  }
</script>
<style lang="scss">
  @import '@/assets/scss/cruds/_crudStyles.scss';
  .ql-editor .ql-video {
    display: block;
    max-width: 100%;
    width: 70%;
    margin: auto;
    height: 300px;
  }
  .v-textarea textarea {
    height: 80px;
  }
  .text-box strong {
    font-weight: 600;
  }
</style>
